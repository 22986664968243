$( document ).ready(function() {
    $(".phone-mask").inputmask("+7 (999) 999-9999",{ "clearIncomplete": false });


    $( ".drp" ).hover(function() {
        $( 'body').toggleClass('op');
    });


    $('.js-catalog-min-slider').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    });

    $('.js-blog-slider').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: true
    });


    $('.js-text-slider').on('init reInit', function(event, slick) {
        $('.pagingInfo .number-slide').html('<span class="numb-prev">' + 1 + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');
    });
    $('.js-text-slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        $('.pagingInfo .number-slide').html('<span class="numb-prev">' + (currentSlide + 1) + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');
    });


    $('.js-text-slider').slick({
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        appendDots:$('.pagingInfo ')
    });

    $('.cart-product__slider-for.slider-for').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: '.slider-nav',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    });
    $('.cart-product__slider-nav.slider-nav').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    vertical: true,
                    variableWidth: false,
                    focusOnSelect: true,
                    arrows: true
                }
            }
        ]
    });


    $(".js-toggler").on('click', function(event){
        $(this).toggleClass('open');
        $(".header-mob-all").stop().toggleClass('open');
        $("body").toggleClass('overfl');
    });

    $(".footer__item-title").on('click', function(event){
        $(this).parent().toggleClass('open');
    });

    $(".js-search").on('click', function(event){
        $('.header-mid__group-search-tt').toggleClass('op');
        $('.header-mid__group-search-block').removeClass('open');
        $('.header-mid__group-search-block').removeClass('open');

    });

    $(".js-search-mob").on('click', function(event){
        $('.header-mid__group-search-block').toggleClass('open');

    });

    $( ".header-mid__group-search-tt-in input" ).keyup(function() {
        $('.header-mid__group-search-block').addClass('open');
    });



    $('.filter__title').on('click', function(event){
        $(this).toggleClass('open').parent('.filter__item').toggleClass('open');
    });

    $('.filter__title-t-btn').on('click', function(event){
        $(this).toggleClass('open').parent().toggleClass('on');
    });

    $('.js-filter-mob').on('click', function (event) {
        $('.filter').addClass('op');
        $('body').addClass('overfl');
    });
    $('.js-filter-close').on('click', function (event) {
        $('.filter').removeClass('op');
        $('body').removeClass('overfl');
    });

    $(".filter__group-check, .address__map-address-inner, .ord__list-item-group-scroll").mCustomScrollbar();

    $('.catalog-page__content-tx-btn').on('click', function(event){
        $(this).toggleClass('open').parent().toggleClass('open');
    });

    $('.catalog__item-fav').on('click', function(event){
        $(this).parent().toggleClass('catalog__item-fv');
    });




    ymaps.ready(init);
    function init () {
        var myMap = new ymaps.Map("map", {
            center: [55.77101400, 37.63209300],
            zoom: 13,
            controls: ["zoomControl"]
        });

        myMap.controls.add('fullscreenControl', {float: 'left'});

        myMap.geoObjects
            .add(new ymaps.Placemark([55.77101400, 37.63209300], {
                balloonContent: '<div class="ballon"><div class="ballon-img" style="background-image: url(\'assets/images/map-im.jpg\'")></div> <div class="ballon__title">ТЦ «Центр Города»</div><div class="ballon__addr">г. Краснодар, ул. Красная, 176</div><div class="ballon__tel">+7 (989) 290-25-03</div><div class="ballon__time">10.00-22.00, без перерыва и выходных</div><a href="#"  class="ballon__btn">о магазине</a></div>',
                preset: 'islands#blackStretchyIcon',
                draggable: true,
            }, {
                iconLayout: 'default#image',
                iconImageHref: 'assets/images/svg/pinmap.svg',
                iconImageSize: [55, 55],
                iconImageOffset: [-80, -70],
                hideIconOnBalloonOpen: false
            }))
    }





    $('.down').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $('.up').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });

    $(".polzunok-5").slider({
        min: 0,
        max: 1200,
        values: [300, 11870],
        range: true,
        animate: true,
        slide : function(event, ui) {
            $(".polzunok-input-5-left").val(ui.values[ 0 ]);
            $(".polzunok-input-5-right").val(ui.values[ 1 ]);
        }
    });
    $(".polzunok-input-5-left").val($(".polzunok-5").slider("values", 0));
    $(".polzunok-input-5-right").val($(".polzunok-5").slider("values", 1));
    $(document).focusout(function() {
        var input_left = $(".polzunok-input-5-left").val(),
            opt_left = $(".polzunok-5").slider("option", "min"),
            where_right = $(".polzunok-5").slider("values", 1),
            input_right = $(".polzunok-input-5-right").val(),
            opt_right = $(".polzunok-5").slider("option", "max"),
            where_left = $(".polzunok-5").slider("values", 0);
        if (input_left > where_right) {
            input_left = where_right;
        }
        if (input_left < opt_left) {
            input_left = opt_left;
        }
        if (input_left == "") {
            input_left = 0;
        }
        if (input_right < where_left) {
            input_right = where_left;
        }
        if (input_right > opt_right) {
            input_right = opt_right;
        }
        if (input_right == "") {
            input_right = 0;
        }
        $(".polzunok-input-5-left").val(input_left);
        $(".polzunok-input-5-right").val(input_right);
        $(".polzunok-5").slider( "values", [ input_left, input_right ] );
    });
    $('.polzunok-5').draggable();


    $('.js-article-main').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });








    $('.js-banner-slider').on('init reInit', function(event, slick) {
        $('.pagingInfo .number-slide').html('<span class="numb-prev">' + 1 + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');
    });

    $('.slider-nav').slick({
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed:5000,
        focusOnSelect: true,
        appendDots: $('.slider-dots-box'),
        dotsClass: 'slider-dots',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false
                }
            }
        ]
    });

// On before slide change
    $('.slider-nav').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.slider-dots-box button').html('');
    }).trigger('beforeChange');

// On before slide change
    $('.slider-nav').on('afterChange', function(event, slick, currentSlide){
        $('.slider-dots-box button').html('');
        $('.slider-dots-box .slick-active button')
            .html(`<svg class="progress-svg" width="40" height="40">
		<g transform="translate(20,20)">
      <circle class="circle-go" r="19" cx="0" cy="0"></circle>
		</g>
    </svg>`);
        $('.pagingInfo .number-slide').html('<span class="numb-prev">' + (currentSlide + 1) + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');

    }).trigger('afterChange');




});
